//
// fixbar.scss
// Extended from Bootstrap
//
.fixbar-wraper {
  position: fixed;
  bottom: 6rem;
  right: 0;
  // background-color: red;
  z-index: 9999999;
  padding: 2rem;
}

.bar-item {
  width: 100%;
}

.bar-download, .bar-customerservice, .bar-message {
  width: 3.1rem;
  margin: 1rem;
  .img__nomal {
    display: block;
  }
  .img__active {
    display: none;
  }
  .font-tost{
    color: #F86442;
  }
    &:hover {
      .img__nomal {
        display: none;
      }
      .img__active {
        display: block;
      }
      .font-tost{
        color: #F86442;
      }
    }
}

.bar-customerservice {
  .service-code {
    display: none;
  }
  &:hover {
    .service-code {
      display: block;
    }
  }
}

.service-code {
  width: 8rem;
  position: absolute;
  top: -1rem;
  right: 4rem;
}
.service-phone{
  right: 5rem !important;
}

.flex-h {
  display: flex;
  flex-direction: row;
}
.flex-v {
  display: flex;
  flex-direction: column;
}
