//
// footer.scss
// Theme component
//

// Brand image

.bg-dark .footer-brand {
  max-height: $navbar-brand-height;
  filter: brightness(0) invert(1);
}

.footer-brand {
  max-height: $navbar-brand-height;
}

.border-line {
  width: 100%;
  border-bottom: 2px solid $gray-500;
}

.bg-dark .texxx {
  color: $gray-600 !important;
}

.register {
  display: none;
}
.accordion-button{
  background-color: transparent !important;
  padding: 1rem 0 !important;
}
.accordion-button:not(.collapsed){
  background-color: transparent !important;

}
.accordion-item{
  color: #869AB8 !important;
  background-color: transparent !important;
  border: none;
  border-bottom: 1px solid #869AB8 !important;
}
.accordion-item:last-child{
  border-bottom: 0 !important;

}
.accordion-button::after{
  margin-left: auto !important
}
.accordion-body{
  padding: 0 3rem !important;
}
.accordion-button{
  color: #869AB8 !important;
}