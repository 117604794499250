//
// navbar.scss
// Extended from Bootstrap
//

.navbar {
  transition: $transition-base;
  transition-property: background-color, color;
  z-index: $zindex-fixed;
}


// Dark version

.navbar-dark {

  @include media-breakpoint-down(lg) {

    .navbar-nav .nav-item .nav-link {
      color: $navbar-light-color;

      &:hover, &:focus {
        color: $navbar-light-hover-color;
      }
    }

    .navbar-nav .nav-item.active .nav-link,
    .navbar-nav .nav-item .nav-link.active {
      color: $navbar-light-active-color;
    }

    .navbar-collapse .navbar-toggler {
      color: $navbar-light-color;
    }
  }
}

.logo-toggle {
  margin-right: 7rem;
}


// Contaner

.navbar > .container,
.navbar > .container-fluid {
  padding-left: $grid-gutter-width / 2 !important;
  padding-right: $grid-gutter-width / 2 !important;
}


// Brand

.navbar-brand {
  font-weight: $navbar-brand-font-weight;
}

.navbar-brand-img {
  max-height: $navbar-brand-height;
  width: auto;
}

.navbar-dark .navbar-brand {
  filter: brightness(0) invert(1);
}


// Button

.navbar-btn {
  margin: 0 .2rem;

  @include media-breakpoint-down(lg) {
    width: 100%;
    padding: $btn-padding-y $btn-padding-x;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    font-size: $btn-font-size;
  }
}


// Navigation

.navbar-nav .nav-link {
  // font-weight: $navbar-nav-link-font-weight;

  &:hover, &:focus {
    outline: none;
  }
}


// Boxed layout

.navbar-dark.fixed-top > .container {
  position: relative;

  &::after {
    content: "";
    position: absolute;
    right: 0;
    bottom: -$navbar-padding-y;
    left: 0;
    border-top: $border-width solid fade-out($white, .8);
  }
}


// Collapse

@include media-breakpoint-down(lg) {

  .navbar-collapse {
    position: fixed;
    top: $spacer;
    left: $spacer;
    height: auto;
    max-height: calc(100% - #{$spacer * 2}) !important;
    width: calc(100% - #{$spacer * 2});
    background-color: $dropdown-bg;
    border-radius: $dropdown-border-radius;
    box-shadow: $box-shadow-dark-lg;
    overflow-x: hidden;
    overflow-y: scroll;
  }

  // Animation

  .navbar-collapse.show,
  .navbar-collapse.collapsing {
    transition: $transition-base;
    transition-property: opacity, transform, -webkit-transform;
    transform-origin: top right;
  }

  .navbar-collapse.show {
    opacity: 1;
    transform: scale(1);
  }

  .navbar-collapse.collapsing {
    opacity: 0;
    transform: scale(.9);
  }

}


// Toggler

.navbar .navbar-toggler {
  margin-left: auto;
}

.navbar-collapse .navbar-toggler {
  position: absolute;
  top: $spacer;
  right: $spacer;
  z-index: 1;

  @include media-breakpoint-up(lg) {
    display: none;
  }
}


// Navigation

.navbar-collapse .navbar-nav .nav-item {

  @include media-breakpoint-down(lg) {
    padding: $navbar-nav-item-spacing;

    + .nav-item {
      border-top: $border-width solid $gray-200;
    }
  }
}

.navbar-collapse .navbar-nav .nav-link {

  @include media-breakpoint-down(lg) {
    padding-top: 0;
    padding-bottom: 0;
  }
}


// Dropdown menu

.navbar-nav .dropdown-menu {
  box-shadow: none;

  @include media-breakpoint-up(lg) {
    box-shadow: $box-shadow-dark-lg;
  }
}

.navbar-collapse .navbar-nav .dropdown-menu {

  @include media-breakpoint-down(lg) {
    min-width: 0;
    padding: 0;
  }
}

.navbar-collapse .navbar-nav .dropdown > .dropdown-menu {

  @include media-breakpoint-down(lg) {
    display: block !important;
  }
}

.navbar-collapse .navbar-nav .dropend > .dropdown-menu {

  @include media-breakpoint-down(lg) {
    padding-top: $spacer;
    padding-bottom: $spacer;
    padding-left: $spacer / 2;
  }

  @include media-breakpoint-up(lg) {
    left: 100%;
    right: auto;
  }
}


// Dropdown item

.navbar-collapse .navbar-nav .dropdown-item,
.navbar-collapse .navbar-nav .dropdown-header {

  @include media-breakpoint-down(lg) {
    margin-left: 0;
    margin-right: 0;
    padding-left: 0;
    padding-right: 0;
  }
}


// Dropdown toggle

.navbar-nav .dropdown > .dropdown-toggle {

  &::after {
    display: none;
  }
}

.navbar-collapse .navbar-nav .dropdown > .dropdown-toggle {

  @include media-breakpoint-down(lg) {
    margin-bottom: $navbar-dropdown-toggle-margin-bottom;
    pointer-events: none;
  }
}

.navbar-nav .dropend > .dropdown-toggle {
  display: flex;

  &::after {
    margin-left: auto;
  }
}

.navbar-collapse .navbar-nav .dropend > .dropdown-toggle {

  @include media-breakpoint-down(lg) {

    &::after {
      content: "\e92e";
    }

    &[aria-expanded="true"]::after {
      transform-origin: center center;
      transform: rotate(180deg);
    }
  }
}


// Dropdown image

.navbar-nav .dropdown-img-start {
  height: 100%;
  display: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: $dropdown-padding-y $dropdown-padding-x;
  background: no-repeat center center / cover;
  border-top-left-radius: $border-radius;
  border-bottom-left-radius: $border-radius;

  @include media-breakpoint-up(lg) {
    display: flex;
  }

  // Overlay

  &::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: fade-out($primary, .2);
    border-radius: inherit;
  }

  // Content

  * {
    position: relative;
  }
}

.navbar-nav .dropdown-menu-lg .dropdown-img-start,
.navbar-nav .dropdown-menu-xl .dropdown-img-start {

  @include media-breakpoint-up(lg) {
    padding: $dropdown-padding-y-lg $dropdown-padding-x-lg;
  }
}


// Dropdown body

.navbar-nav .dropdown-body {

  @include media-breakpoint-up(lg) {
    padding: $dropdown-padding-y $dropdown-padding-x;
  }
}

.navbar-nav .dropdown-menu-lg .dropdown-body,
.navbar-nav .dropdown-menu-xl .dropdown-body {

  @include media-breakpoint-up(lg) {
    padding: $dropdown-padding-y-lg $dropdown-padding-x-lg;
  }
}

// Dropdown list group

.navbar-nav .dropdown-menu .list-group-item {
  display: flex;
  align-items: center;
  padding-top: $spacer;
  padding-bottom: $spacer;
  color: inherit;

  &:hover {
    text-decoration: none;
  }
}


// Toggle dropdown on hover

@include media-breakpoint-up(lg) {

  .navbar-nav .dropdown-menu {
    display: none;
    opacity: 0;
    transition: $transition-base;
    transition-property: opacity, transform, -webkit-transform;
  }

  .navbar-nav .dropend > .dropdown-menu {
    transform: translateY(10px);
  }

  .navbar-nav .dropdown > .dropdown-menu {
    left: 50%;
    transform: translate(-50%, 10px);
  }

  .navbar-nav .dropdown-menu.showing {
    display: block;
  }

  .navbar-nav .dropdown-menu.show {
    display: block;
    opacity: 1;
  }

  .navbar-nav .dropend > .dropdown-menu.show {
    transform: translateY(0);
  }

  .navbar-nav .dropdown > .dropdown-menu.show {
    transform: translate(-50%, 0);
  }
}

.prod_phone_icon {
  width: 44.1px;                       
  height: 44.1px;
  background: url('../img/nav.png') no-repeat 0px 0px;
  background-size: 89.95px 89.95px;
}
.prod_shop_icon {
  width: 44.1px;                       
  height: 44.1px;
  background: url('../img/nav.png') no-repeat -45.85px 0px;
  background-size: 89.95px 89.95px;
}
.prod_wechat_icon {
  width: 44.1px;                       
  height: 44.1px;
  background: url('../img/nav.png') no-repeat 0px -45.85px;
  background-size: 89.95px 89.95px;
}
.prod_xcd_icon {
  width: 45.36px;
  height: 45.36px;
  background: url(../img/nav.png) no-repeat -70.74px -70.74px;
  background-size: 138.78px 138.78px;
}

#PopupDilog{
  position: fixed;
  width: 100%;
  height: 100vh;
  display: none;
  background-color: rgba($color: #000000, $alpha: 0.3);
  z-index: 1031;
}
.Popup-update{
  width: 50rem;
  height: 36rem;
  padding: 30px 0;
  background: url(../img/Popup/propbg.png) no-repeat;
  background-size: 100%;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  
  text-align: center;
  img {
    width: 93px;
    height: 22px;
    margin-bottom: 20px;
  }
}
.Popup-tost{
  font-size: 48px;
  color: #F86442; 
  font-weight: 600;
}
.Popup-content{
  width: 679px;
  height: 261px;
  margin: 0 auto ;
  background: url(../img/Popup/Popup_content_bg.png) no-repeat;
  background-size: 100%;
  padding-top: 25px;
  margin-top: 20px;
  &_title{
    font-size: 22px;
    color: #F86442;
  }
  &_info{
    margin-top: 30px;
    display: flex;
    align-items: center;
    img{
      width: 34px;
      height: 19px;
      margin: 0;
      margin-right: 10px;
    }
  }
}
.flex-1 {
  flex:1;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
.new{
  color: #F86442;
}
.popup_time{
  font-size: 14px;
  color: #5D4E60;
}
.Popup-button{
  width: 334px;
  height: 56px;
  margin: 35px auto;
  margin-bottom: 0;
  img{
    width: 100%;
    height: auto;
  }
}
.tost{
  margin-top: 18px;
}
@media only screen and (max-width: 500px){
  .Popup-update{
    width: 100%;
    height: auto;
    padding: 5px 0;
    img{
      margin: 0;
    }
  }
  .Popup-tost{
    font-size: 16px;
    margin-top: 0;
  }
  .Popup-content{
    width: 98%;
    height: 9.375rem;
    background-size: 100%;
    padding-top: 10px;
    margin-top: 0;
    &_title{
      font-size: 12px;
    }
    &_info{
      flex-direction: column;
      margin-top: 5px;
    }
  }
  .flex-1{
    margin-top: 1px;
    font-size: 14px;
    img{
      width: 29px;
      height: 14px;
    }
  }
  .tost{
    margin-top: 5px;
    font-size: 12px;
  }
  .Popup-button{
    margin-top: 5px;
    width: 200px;
    height: auto;
    background-size: 100%;
  }
}