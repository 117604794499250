.text-sm {
  font-size: $font-size-sm;
}

.tuijian {
  width: 5rem;
  position: absolute;
  z-index: 999;
  right: -0.1rem;
  top: 0;
}