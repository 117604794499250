.form-label {
  margin-bottom: 0 !important;
}

.text-must {
  color: #f86442;
}

.text-white {
  color: #fff;
}

.btn-code {
  min-height: $input-height-xs;
  padding: $input-padding-y-xs $input-padding-x-xs;
  line-height: $input-line-height-xs;
  font-size: $input-font-size-xs;
}

/**
 * phone样式
 */
 @media only screen and (min-width: 500px) {

  .form-label {
    text-align: left;
    padding: 0 !important;
  }

}
