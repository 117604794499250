
.boss-say{
  .boss-say__capture{
    width: 100%;
    &::before {
      content: " ";
      position: absolute;
      top: 0;
      left: 0;
      z-index: 18;
      width: 100%;
      height: 100%;
      background: linear-gradient(rgba(#aaa, 0.1) 0%, rgba(#000, 0.1) 100%);
    }
  
    &::after {
      content: " ";
      position: absolute;
      width: 77px;
      height: 46px;
      top: 50%;
      left: 50%;
      z-index: 19;
      transform: translate(-50%, -50%);
      background: url("../img/boss/lbs_icon_bf1.png") no-repeat
        center;
      background-size: contain;
      transition: all 344ms;
    }
  }
  &:hover {
    .boss-say__capture {
      &::after {
        background: url("../img/boss/lbs_icon_bf.png") no-repeat
          center;
        background-size: contain;
      }
    }
  }
}

.video-modal{
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1999;
  .video-mask {
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
  }

  .video-container {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 2000;
    transform: translate(-50%, -50%);
    width: 600px;
    height: 370px;
    background-color: black;
  }
}
.is-show{
  display: block !important;
}