//
// type.scss
// Extended from Bootstrap
//

//
// Font sizing
//

@include media-breakpoint-down(lg) {
  .font-size-lg {
    font-size: $font-size-lg-md !important;
  }
}

.font-size-mini {
  font-size: $font-size-mini !important;
}
.font-size-sm {
  font-size: $font-size-sm !important;
}
.font-size-xs {
  font-size: $font-size-xs !important;
}
