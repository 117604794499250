//
// list-group.scss
// Extended from Bootstrap
//

.list-group-flush {

  .list-group-item {
    padding-left: 0;
    padding-right: 0;

    &:first-child {
      padding-top: 0 !important;
      border-top: 0;
    }

    &:last-child {
      padding-bottom: 0 !important;
      border-bottom: 0;
    }
    &:hover{
      h4{
        color: #F86442;
      }
    }
  }
}
