// $width: 100%;
// .zoom_calc{
//   zoom: calc(100%/calc(880/#{$width}));
// }
.margin-auto{
  margin: 0 auto;
  margin-bottom: 16px;
}
.pr-none{
  padding-right: 0 !important;
}
.user_case_wx-1 {
  width: 96px;                       
  height: 96px;
  background: url('../img/Flyer/Flyer.png') no-repeat 0px 0px;
  background-size: 298px 298px;
}
.user_case_wx-2 {
  width: 96px;                       
  height: 96px;
  background: url('../img/Flyer/Flyer.png') no-repeat -101px 0px;
  background-size: 298px 298px;
}
.user_case_wx-3 {
  width: 96px;                       
  height: 96px;
  background: url('../img/Flyer/Flyer.png') no-repeat 0px -101px;
  background-size: 298px 298px;
}
.user_case_wx-4 {
  width: 96px;                       
  height: 96px;
  background: url('../img/Flyer/Flyer.png') no-repeat -101px -101px;
  background-size: 298px 298px;
}
.user_case_wx-5 {
  width: 96px;                       
  height: 96px;
  background: url('../img/Flyer/Flyer.png') no-repeat -202px 0px;
  background-size: 298px 298px;
}
.user_case_wx-6 {
  width: 96px;                       
  height: 96px;
  background: url('../img/Flyer/Flyer.png') no-repeat -202px -101px;
  background-size: 298px 298px;
}
.user_case_wx-7 {
  width: 96px;                       
  height: 96px;
  background: url('../img/Flyer/Flyer.png') no-repeat 0px -202px;
  background-size: 298px 298px;
}
.user_case_wx-8 {
  width: 96px;                       
  height: 96px;
  background: url('../img/Flyer/Flyer.png') no-repeat -101px -202px;
  background-size: 298px 298px;
}