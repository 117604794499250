/**
 * web样式
 */
 .download-method__item{
  opacity: 1!important;
 }

@media only screen and (min-width: 500px) {

  .download-method__item {
    cursor: pointer;
    position: relative;
    // overflow: hidden;
    // width: 148px;
    // height: 148px;
    // background: $white;
    // box-shadow: 0px 2px 16px 0px rgba(#7e635d, 0.08);
    // border-radius: $radius-size;
    // display: flex;
    // align-items: center;
    // justify-content: center;
    .method-img__dwonload {
      display: none;
      // top: -148px;
      opacity: 1;
      z-index: 10;
      width: 100%;
      padding: 11% 15%;
      background-color: $white;
      animation: mymove 200ms ease;
      -webkit-animation:mymove 200ms ease; /*Safari and Chrome*/
      // animation-direction:alternate;/*轮流反向播放动画。*/
      animation-timing-function: ease; /*动画的速度曲线*/
      /* Safari 和 Chrome */
      -webkit-animation:mymove 200ms ease;
      // -webkit-animation-direction:alternate;/*轮流反向播放动画。*/
      -webkit-animation-timing-function: ease; /*动画的速度曲线*/
    }
  
    &:hover {
      .method-img__dwonload {
        top: 0;
        opacity: 1;
        display: block;
        z-index: 9999999 !important;
      }
      .method-img__nomal {
        display: none;
        animation: mymove 200ms ease;
        -webkit-animation:mymove 200ms ease; /*Safari and Chrome*/
        // animation-direction:alternate;/*轮流反向播放动画。*/
        animation-timing-function: ease; /*动画的速度曲线*/
        /* Safari 和 Chrome */
        -webkit-animation:mymove 200ms ease;
        // -webkit-animation-direction:alternate;/*轮流反向播放动画。*/
        -webkit-animation-timing-function: ease; /*动画的速度曲线*/
      }
      @keyframes myhide {
        0%{
          transform: scale(1);
          -webkit-transform: scale(1);
        }
        100%{
          transform: scale(0);
          -webkit-transform: scale(0);
        }
      }
      @-webkit-keyframes myhide /*Safari and Chrome*/
      {
        0%{
          transform: scale(1);
          -webkit-transform: scale(1);
        }
        100%{
          transform: scale(0);
          -webkit-transform: scale(0);
        }
      }
    } 
  
    @keyframes mymove {
      0%{
      transform: scale(0.7);
      -webkit-transform: scale(0.7);
      }
      100%{
          transform: scale(1);
          -webkit-transform: scale(1);
      }
    } 
    @-webkit-keyframes mymove /*Safari and Chrome*/
    {
      0%{
        transform: scale(0.7);
        -webkit-transform: scale(0.7);
        }
      100%{
          transform: scale(1);
          -webkit-transform: scale(0);
      }
    }
  
  }

}

/**
 * phone样式
 */
@media only screen and (max-width: 500px) {

  .download-method__item {
    cursor: pointer;
    position: relative;
  
    .method-img__dwonload {
      display: none;
      // top: -148px;
      opacity: 1;
      z-index: 10;
      width: 100%;
      padding: 11% 15%;
      background-color: $white;
      animation: mymove 200ms ease;
      -webkit-animation:mymove 200ms ease; /*Safari and Chrome*/
      // animation-direction:alternate;/*轮流反向播放动画。*/
      animation-timing-function: ease; /*动画的速度曲线*/
      /* Safari 和 Chrome */
      -webkit-animation:mymove 200ms ease;
      // -webkit-animation-direction:alternate;/*轮流反向播放动画。*/
      -webkit-animation-timing-function: ease; /*动画的速度曲线*/
    }
  
  }

}
