//
// reboot.scss
// Extended from Bootstrap
//

html {
  overflow-x: hidden !important;
}

@media (hover: none) {
  body {
    overflow-x: hidden !important;
  }
}