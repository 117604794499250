.company-company1 {
  width: 107.2px;                       
  height: 32px;
  background: url('../img/home/company.png') no-repeat 0px 0px;
  background-size: 325.6px 270px;
}
.company-company10 {
  width: 107.2px;                       
  height: 32px;
  background: url('../img/home/company.png') no-repeat 0px -34px;
  background-size: 325.6px 270px;
}
.company-company11 {
  width: 107.2px;                       
  height: 32px;
  background: url('../img/home/company.png') no-repeat 0px -68px;
  background-size: 325.6px 270px;
}
.company-company12 {
  width: 107.2px;                       
  height: 32px;
  background: url('../img/home/company.png') no-repeat -109.2px 0px;
  background-size: 325.6px 270px;
}
.company-company13 {
  width: 107.2px;                       
  height: 32px;
  background: url('../img/home/company.png') no-repeat -109.2px -34px;
  background-size: 325.6px 270px;
}
.company-company14 {
  width: 107.2px;                       
  height: 32px;
  background: url('../img/home/company.png') no-repeat -109.2px -68px;
  background-size: 325.6px 270px;
}
.company-company15 {
  width: 107.2px;                       
  height: 32px;
  background: url('../img/home/company.png') no-repeat 0px -102px;
  background-size: 325.6px 270px;
}
.company-company16 {
  width: 107.2px;                       
  height: 32px;
  background: url('../img/home/company.png') no-repeat -109.2px -102px;
  background-size: 325.6px 270px;
}
.company-company17 {
  width: 107.2px;                       
  height: 32px;
  background: url('../img/home/company.png') no-repeat 0px -136px;
  background-size: 325.6px 270px;
}
.company-company18 {
  width: 107.2px;                       
  height: 32px;
  background: url('../img/home/company.png') no-repeat -109.2px -136px;
  background-size: 325.6px 270px;
}
.company-company19 {
  width: 107.2px;                       
  height: 32px;
  background: url('../img/home/company.png') no-repeat 0px -170px;
  background-size: 325.6px 270px;
}
.company-company2 {
  width: 107.2px;                       
  height: 32px;
  background: url('../img/home/company.png') no-repeat -109.2px -170px;
  background-size: 325.6px 270px;
}
.company-company20 {
  width: 107.2px;                       
  height: 32px;
  background: url('../img/home/company.png') no-repeat -218.4px 0px;
  background-size: 325.6px 270px;
}
.company-company21 {
  width: 107.2px;                       
  height: 32px;
  background: url('../img/home/company.png') no-repeat -218.4px -34px;
  background-size: 325.6px 270px;
}
.company-company22 {
  width: 107.2px;                       
  height: 32px;
  background: url('../img/home/company.png') no-repeat -218.4px -68px;
  background-size: 325.6px 270px;
}
.company-company23 {
  width: 107.2px;                       
  height: 32px;
  background: url('../img/home/company.png') no-repeat -218.4px -102px;
  background-size: 325.6px 270px;
}
.company-company24 {
  width: 107.2px;                       
  height: 32px;
  background: url('../img/home/company.png') no-repeat -218.4px -136px;
  background-size: 325.6px 270px;
}
.company-company3 {
  width: 107.2px;                       
  height: 32px;
  background: url('../img/home/company.png') no-repeat -218.4px -170px;
  background-size: 325.6px 270px;
}
.company-company4 {
  width: 107.2px;                       
  height: 32px;
  background: url('../img/home/company.png') no-repeat 0px -204px;
  background-size: 325.6px 270px;
}
.company-company5 {
  width: 107.2px;                       
  height: 32px;
  background: url('../img/home/company.png') no-repeat -109.2px -204px;
  background-size: 325.6px 270px;
}
.company-company6 {
  width: 107.2px;                       
  height: 32px;
  background: url('../img/home/company.png') no-repeat -218.4px -204px;
  background-size: 325.6px 270px;
}
.company-company7 {
  width: 107.2px;                       
  height: 32px;
  background: url('../img/home/company.png') no-repeat 0px -238px;
  background-size: 325.6px 270px;
}
.company-company8 {
  width: 107.2px;                       
  height: 32px;
  background: url('../img/home/company.png') no-repeat -109.2px -238px;
  background-size: 325.6px 270px;
}
.company-company9 {
  width: 107.2px;                       
  height: 32px;
  background: url('../img/home/company.png') no-repeat -218.4px -238px;
  background-size: 325.6px 270px;
}

.company-close {
  width: 78px;                       
  height: 78px;
  background: url('../img/home/icon.png') no-repeat -499px 0px;
  background-size: 577px 494px;
}
.company-home_icon_1 {
  width: 180px;                       
  height: 180px;
  background: url('../img/home/icon.png') no-repeat 0px 0px;
  background-size: 577px 494px;
}
.company-home_icon_2 {
  width: 180px;                       
  height: 180px;
  background: url('../img/home/icon.png') no-repeat -185px 0px;
  background-size: 577px 494px;
}
.company-home_icon_3 {
  width: 180px;                       
  height: 180px;
  background: url('../img/home/icon.png') no-repeat 0px -185px;
  background-size: 577px 494px;
}
.company-home_icon_4 {
  width: 180px;                       
  height: 180px;
  background: url('../img/home/icon.png') no-repeat -185px -185px;
  background-size: 577px 494px;
}
.company-home_icon_group {
  width: 80px;                       
  height: 80px;
  background: url('../img/home/icon.png') no-repeat -370px -258px;
  background-size: 577px 494px;
}
.company-home_icon_group1 {
  width: 124px;                       
  height: 124px;
  background: url('../img/home/icon.png') no-repeat -370px 0px;
  background-size: 577px 494px;
}
.company-home_icon_pc {
  width: 80px;                       
  height: 80px;
  background: url('../img/home/icon.png') no-repeat -258px -370px;
  background-size: 577px 494px;
}
.company-home_icon_pc1 {
  width: 124px;                       
  height: 124px;
  background: url('../img/home/icon.png') no-repeat -370px -129px;
  background-size: 577px 494px;
}
.company-home_icon_time {
  width: 60px;                       
  height: 60px;
  background: url('../img/home/icon.png') no-repeat -499px -164px;
  background-size: 577px 494px;
}
.company-home_icon_time1 {
  width: 124px;                       
  height: 124px;
  background: url('../img/home/icon.png') no-repeat 0px -370px;
  background-size: 577px 494px;
}
.company-home_icon_txy {
  width: 80px;                       
  height: 80px;
  background: url('../img/home/icon.png') no-repeat -343px -370px;
  background-size: 577px 494px;
}
.company-home_icon_txy1 {
  width: 124px;                       
  height: 124px;
  background: url('../img/home/icon.png') no-repeat -129px -370px;
  background-size: 577px 494px;
}
.company-open {
  width: 76px;                       
  height: 76px;
  background: url('../img/home/icon.png') no-repeat -499px -83px;
  background-size: 577px 494px;
}
.download-method__item{
  opacity: 1!important;
 }