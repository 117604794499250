
.width100{
  width: 100%;
}
.case_item{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1.375rem 0;
  .zoom6{
    zoom: 0.7;
    -moz-transform: scale(0.7);
  }
}
.sjcc_qccode_1 {
  width: 240px;                       
  height: 240px;
  background: url('../img/mobil-phone/phone-case.png') no-repeat 0px 0px;
  background-size: 975px 730px;
}
.sjcc_qccode_10 {
  width: 240px;                       
  height: 240px;
  background: url('../img/mobil-phone/phone-case.png') no-repeat -245px 0px;
  background-size: 975px 730px;
}
.sjcc_qccode_11 {
  width: 240px;                       
  height: 240px;
  background: url('../img/mobil-phone/phone-case.png') no-repeat 0px -245px;
  background-size: 975px 730px;
}
.sjcc_qccode_12 {
  width: 240px;                       
  height: 240px;
  background: url('../img/mobil-phone/phone-case.png') no-repeat -245px -245px;
  background-size: 975px 730px;
}
.sjcc_qccode_2 {
  width: 240px;                       
  height: 240px;
  background: url('../img/mobil-phone/phone-case.png') no-repeat -490px 0px;
  background-size: 975px 730px;
}
.sjcc_qccode_3 {
  width: 240px;                       
  height: 240px;
  background: url('../img/mobil-phone/phone-case.png') no-repeat -490px -245px;
  background-size: 975px 730px;
}
.sjcc_qccode_4 {
  width: 240px;                       
  height: 240px;
  background: url('../img/mobil-phone/phone-case.png') no-repeat 0px -490px;
  background-size: 975px 730px;
}
.sjcc_qccode_5 {
  width: 240px;                       
  height: 240px;
  background: url('../img/mobil-phone/phone-case.png') no-repeat -245px -490px;
  background-size: 975px 730px;
}
.sjcc_qccode_6 {
  width: 240px;                       
  height: 240px;
  background: url('../img/mobil-phone/phone-case.png') no-repeat -490px -490px;
  background-size: 975px 730px;
}
.sjcc_qccode_7 {
  width: 240px;                       
  height: 240px;
  background: url('../img/mobil-phone/phone-case.png') no-repeat -735px 0px;
  background-size: 975px 730px;
}
.sjcc_qccode_8 {
  width: 240px;                       
  height: 240px;
  background: url('../img/mobil-phone/phone-case.png') no-repeat -735px -245px;
  background-size: 975px 730px;
}
.sjcc_qccode_9 {
  width: 240px;                       
  height: 240px;
  background: url('../img/mobil-phone/phone-case.png') no-repeat -735px -490px;
  background-size: 975px 730px;
}